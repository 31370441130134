import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

import SEO from '../../../components/SEO';
import Body from '../../../components/layout/Body';
import ResponsiveContainer from '../../../components/layout/ResponsiveContainer';
import GetStarted from '../../../components/cta/GetStarted';
import Blockquote from '../../../components/frills/Blockquote';

import { ViewportBreakpoints } from '../../../components/cssConstants';

import H2 from '@rotaready/frecl/build/H2';
import H3 from '@rotaready/frecl/build/H3';
import H4 from '@rotaready/frecl/build/H4';
import Text from '@rotaready/frecl/build/Text';
import Pill from '@rotaready/frecl/build/Pill';
import Button from '@rotaready/frecl/build/Button';
import ContactForm from '../../../components/forms/ContactForm';

const HeroContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    display: block;
    position: relative;
  }
`;

const HeroImageContainer = styled.div`
  height: 450px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    height: 790px;
    width: 50%;
    margin-left: auto;
    transform: translate3d(0, -80px, 0);
  }
`;

const HeroContentContainer = styled.div`
  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    height: calc(100% - 80px);
  }
`;

const Hero = styled.div`
  margin: 40px 0;
  text-align: center;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin: 0;
    text-align: left;
    width: 40%;
  }
`;

const StatsBox = styled.div`
  padding: 0 20px;
  box-shadow: 0 0 8px 2px rgba(71, 91, 104, 0.1);
  background-color: ${({ theme: { colors } }) => colors.grey10};
  border: 1px solid ${({ theme: { colors } }) => colors.grey20};
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  transform: translateY(-160px);
  flex-wrap: wrap;
`;

const StatItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-width: 200px;
  height: 80px;
  padding: 20px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding: 40px 0;
  }
`;

const StatHeadline = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const StatSubtitle = styled(H4).attrs({
  uistyle: 'grey',
})``;

const BodyWrapper = styled.div`
  max-width: 640px;
  margin: -60px auto 0 auto;
`;

const TileTagContainer = styled.div`
  margin-bottom: 40px;

  > div {
    display: inline-block;
    margin-right: 10px;
  }
`;

const TileTag = styled(Pill).attrs({
  size: 'sm',
  uistyle: 'primary',
})``;

const Section = styled.div`
  margin-bottom: 60px;
`;

const Content = styled.div`
  margin-bottom: 30px;
`;

const TextBlock = styled(Text).attrs({
  size: 'lg',
})``;

const PopQuote = styled(H4).attrs({
  uistyle: 'primary',
  fontWeight: 600,
})`
  text-align: center;
`;

const SubtitleContainer = styled.div`
  margin: 40px 0;
`;

const header = {
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  invertColors: false,
};

class Page extends React.Component {
  render() {
    const {
      data,
    } = this.props;

    return (
      <Body header={header}>
        <SEO
          title="A Restaurant Group Customer Story - Rotaready"
          description="Learn how Rotaready helped this Glasgow based restaurant group better deploy 44 hours every week, and achieve an ROI of 524%"
          url="customer-stories/hospitality-restaurant-group"
        />

        <HeroContainer>
          <HeroImageContainer>
            <GatsbyImage
              image={data.heroBackground.childImageSharp.gatsbyImageData}
              style={{ height: '100%' }}
              alt="Bowl of pasta with garnish"
            />
          </HeroImageContainer>

          <HeroContentContainer>
            <ResponsiveContainer>
              <Hero>
                <TileTagContainer>
                  <TileTag text="Hospitality" />
                </TileTagContainer>

                <H3 uistyle="primary" text="Customer story" fontWeight={600} />

                <SubtitleContainer>
                  <H3 uistyle="brand160" text="Learn how Rotaready helped this Glasgow based restaurant group better deploy 44 hours every week, and achieve an ROI of 524%." />
                </SubtitleContainer>
              </Hero>
            </ResponsiveContainer>
          </HeroContentContainer>
        </HeroContainer>

        <ResponsiveContainer>
          <StatsBox>
            <StatItem>
              <StatHeadline text="11" />
              <StatSubtitle text="sites" />
            </StatItem>

            <StatItem>
              <StatHeadline text="300" />
              <StatSubtitle text="employees" />
            </StatItem>

            <StatItem>
              <StatHeadline text="524%" />
              <StatSubtitle text="return on investment" />
            </StatItem>

            <StatItem>
              <StatHeadline text="44 hours" />
              <StatSubtitle text="better deployed every week!" />
            </StatItem>
          </StatsBox>
        </ResponsiveContainer>

        <ResponsiveContainer>
          <BodyWrapper>
            <Section>
              <Content>
                <H3 uistyle="brand160" text="A restaurant group" />
              </Content>

              <Content>
                <TextBlock text="This Glasgow based restaurant group has grown rapidly since opening its first site, and now consists of 11 unique hospitality venues across the city." />
              </Content>

              <Content>
                <TextBlock text="Up until 2021, the business was using spreadsheets for almost everything; from building staff rotas, to tracking attendance and monitoring costs across the business. But this approach was resulting in a huge amount of wasted time and operational inefficiencies." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Visibility, usability and affordability" />
              </Content>

              <Content>
                <TextBlock text="So, in December 2021 the well-known hospitality business turned to Restaurant Growth Consultants, to help them find a workforce management solution that could help them monitor labour costs more accurately, easily fulfil their HR responsibilities and generally save time for all employees and managers." />
              </Content>
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Visibility of labour and labour management was a key factor. A lot of businesses don’t factor in the obvious uplift when they’re measuring labour. Rotaready offers our client full visibility of contributions in the rota, so they can guarantee that weekly reporting will always give an accurate view of their costs."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>

              <Content>
                <TextBlock text="The chosen solution also needed to be affordable and easy to use for all employees, regardless of their skill sets." />
              </Content>

              <Content>
                <TextBlock text="After carefully reviewing a number of options, the business chose Rotaready as its workforce management partner." />
              </Content>

              <Content>
                <Blockquote
                  text="I came across Rotaready through a recommendation and was really impressed overall, it was the perfect fit for my client. It’s an affordable workforce management solution that ticks all the boxes functionally and is suitable for varying skill sets. For the end user it’s accessible on all levels, everyone was able to hit the ground running."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline1.childImageSharp.gatsbyImageData}
                alt="Cocktail in glass with ice and orange twist"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Implementation & integrations" />
              </Content>

              <Content>
                <TextBlock text="From making the decision to go with Rotaready, all 11 sites were up and running with the rota software and staff fully trained in just over a month." />
              </Content>

              <Content>
                <Blockquote
                  text="Implementation was easy and efficient. The Rotaready team were quick off the mark and offered great training and communication. Ultimately Rotaready is easy to roll out, with good after care."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>

              <Content>
                <TextBlock text="Part of this process was implementing Rotaready with the restaurant group’s other business solutions, including its reservation & payroll systems." />
              </Content>

              <Content>
                <Blockquote
                  text="There are so many different bits of software these days, and with that comes a number of considerations. But Rotaready are very willing and able to work with other businesses to meet their technology requirements. That made the integration process very easy. You guys are my steady eddie, a solution and support I can always rely on."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>

              <Content>
                <TextBlock text="Integrating Rotaready with their reservation system, has given the team visibility of upcoming reservations and covers within Rotaready; enabling them to make more informed scheduling decisions as a result. And the integration between Rotaready and the restaurant group’s payroll solution, means time & attendance data is automatically fed from Rotaready into payroll, ensuring consistently accurate pay runs, without any manual input." />
              </Content>
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="3 favourite features" />
              </Content>

              <Content>
                <TextBlock text="Since implementing Rotaready, the restaurant business has been using the majority of its features; including rota scheduling, time & attendance, cost control & HR. But everyone has their favourites. Here are the restaurant groups’ favourite things about the rota software and why..." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="1. Staff profiles" />
              </Content>

              <Content>
                <TextBlock text="With employment details, pay and holiday histories all captured and stored in one place, staff profiles make all employee information easy to find." />
              </Content>

              <Content>
                <H4 uistyle="primary" text="2. Time & attendance" />
              </Content>

              <Content>
                <TextBlock text="Employees can clock in and out via the Rotaready mobile app. This attendance data is then used to create instantly accurate timesheets, rounded and ready for payroll. Saving time and improving payroll accuracy for the restaurant group." />
              </Content>

              <Content>
                <Blockquote
                  text="The accuracy we gained from Rotaready’s time and attendance feature alone, justifies the investment in the rota software."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>

              <Content>
                <H4 uistyle="primary" text="3. Reporting and cost control" />
              </Content>

              <Content>
                <TextBlock text="With a combination of Rotaready’s strong reports and cost control, the business has full visibility and control over wage costs and the insight needed to make informed scheduling decisions." />
              </Content>

              <Content>
                <Blockquote
                  text="My favourite report within Rotaready is aggregated totals, I look at it every day. You can see performance per site in real-time. I think that’s the best way in our industry to measure labour performance and manage it. It’s a great tool for operators."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>
            </Section>

            <Section>
              <PopQuote text="A combination of all of these factors has helped the business better deploy 44 hours and 56 minutes every week and achieve an ROI of 524%" />
            </Section>

            <Section>
              <Content>
                <Blockquote
                  text="Rotaready has provided substantial cost savings to the business, with the ability to monitor labour in real-time from afar. The inclusion of statutory payments on top of basic salary gives my client a true reflection on what gross paid out would be, so that helps them monitor the profitability of the business."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>
            </Section>

            <Section>
              <GatsbyImage
                image={data.inline2.childImageSharp.gatsbyImageData}
                alt="Sprinkling flour into a silver bowl in a kitchen"
              />
            </Section>

            <Section>
              <Content>
                <H3 uistyle="brand160" text="Continuous development" />
              </Content>

              <Content>
                <TextBlock text="Going forward, Rotaready’s customer success team will continue to work closely with Chris and his restaurant client to ensure they’re leveraging all the benefits of the platform, while feeding back to the engineering team to ensure the product remains as customer-centric as possible." />
              </Content>

              <Content>
                <Blockquote
                  text="The Rotaready team have done really well to find the perfect balance when it comes to product development. They take on crucial customer feedback, whilst keeping on a strict development path."
                  attribution="Chris Barry, Restaurant Consultant"
                />
              </Content>
            </Section>

            <Section>
              <ContactForm
                title="Download the story"
                submitLabel="Download PDF"
                source="customerstories_restaurantgroup"
                label="rotaready.com/customer-stories/hospitality-restaurant-group"
                postTo="marketing"
                checkboxes={[
                  {
                    id: 'marketing',
                    text: 'I\'d like to receive updates, offers and new content from Rotaready by email. You can unsubscribe at any time.',
                    required: false,
                    initial: false,
                  },
                ]}
                onSuccess={(
                  <>
                    <TextBlock>
                      <H3 uistyle="brand160" text="Happy reading 📚" />
                    </TextBlock>

                    <TextBlock>
                      <Text size="lg" text="Thank you for downloading this customer story! Click below to get the file." />
                    </TextBlock>

                    <TextBlock>
                      <a href="/files-static/customer-stories/Rotaready-Story-Restaurant-Group.pdf" download>
                        <Button uistyle="primary" text="Download PDF" size="md" />
                      </a>
                    </TextBlock>
                  </>
                )}
              />
            </Section>
          </BodyWrapper>
        </ResponsiveContainer>

        <GetStarted />
      </Body>
    );
  }
}

export default Page;

export const query = graphql`
  query {
    heroBackground: file(relativePath: { eq: "customer-stories/restaurant-group/header.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 50
          transformOptions: { cropFocus: CENTER, fit: COVER }
        )
      }
    }

    inline1: file(relativePath: { eq: "customer-stories/restaurant-group/inline1.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }

    inline2: file(relativePath: { eq: "customer-stories/restaurant-group/inline2.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 50)
      }
    }
  }
`;
